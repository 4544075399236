<template>
  <div class="pres_total_compra" v-if="ct">
    <div class="cab">RESUMEN</div>
    <v-sheet v-bind="$cfg.styles.marco">
      <div class="columna" >
        <vllabel
          label="FUNERARIA"
          :txt="ct.pnfsuma[2] | num(2)"
          stilot="text-align:right">
        </vllabel>

        <vllabel
          label="OTROS"
          :txt="ct.sup_pd[2] | num(2)"
          stilot="text-align:right">
        </vllabel>

        <vllabel
          label="TOTAL IMP"
          :txt="ct.imp[2] | num(2)"
          stilot="text-align:right"
        ></vllabel>
        <vllabel
          label="AÑO PAS"
          :txt="ct.imp_ant[2] | num(2)"
          stilot="text-align:right"
        >
        </vllabel>

        <vllabel
          label="DIFER %"
          :txt="diferencia | num('%')"
          stilot="text-align:right"
        ></vllabel>
  
         <vllabel
            label="TOTAL CIA"
            :txt="ct.imp_cia[2] | num(2)"
            stilot="text-align:right"
          ></vllabel>
        
      </div>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    ct: { type: Object, default: null },
    edicion: { type: Boolean, default: false }
  },

  computed:{
    diferencia() {
      let dif=Number(this.ct.imp_ant[2]) - Number(this.ct.imp[2]);
      if (isNaN(dif)) return "NO PRES"

      return (Number(this.ct.imp[2])-Number(this.ct.imp_ant[2]))*100/this.ct.imp_ant[2];
    }
  }


};
</script>
